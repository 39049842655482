<template>
    <div v-if="usuarioLogado" class="flotante">
        <div v-if="showMenu">
            <div
                v-if="possuiPermissao('DASHBOARD_CALENDARIO_V')"
                class="transaction-btn-float mb-1 btn-float-common"
                v-bind:class="activePath === '/' ? 'btn-disable' : ''"
                @click="showRoute('/')"
                title="Dashboard"
            >
                <CalendarIcon/>
            </div>
            <div
                v-if="(possuiPermissao('GER_I_RENDAS') || possuiPermissao('GER_U_RENDAS'))"
                class="transaction-btn-float mb-1 btn-float-common"
                v-bind:class="activePath === '/transaction/incomeRecord' ? 'btn-disable' : ''"
                @click="showRoute('/transaction/incomeRecord')"
                title="Lançar Renda"
                >
                <BankCustomIcon/>
            </div>
            <div
                v-if="possuiPermissao('GER_I_AGENDAMENTOS')"
                class="transaction-btn-float mb-1 btn-float-common"
                v-bind:class="activePath === '/transaction/expenseRecord' ? 'btn-disable' : ''"
                @click="showRoute('/transaction/expenseRecord')"
                title="Lançar Despesa"
            >
                <ArrowUpRightIcon/>
            </div>
            <div
                v-if="possuiPermissao('GER_I_RECEIVE')"
                class="transaction-btn-float mb-1 btn-float-common"
                v-bind:class="activePath === '/transaction/receiveRecord' ? 'btn-disable' : ''"
                @click="showRoute('/transaction/receiveRecord')"
                title="Lançar Cobrança"
            >
                <ArrowDownLeftIcon/>
            </div>
        </div>
        <div
            class="btn-menu-float btn-float-common"
            @click="showMenu = !showMenu"
            title="Opcoes"
        >
            <StarIcon/>
        </div>
    </div>
</template>

<script>
	import { possuiPermissao } from "@/helpers/permissions";
	import { mapState } from "vuex";
	export default {
		name: "MenuFloat",
		component: {},

		data () {
			return {
				showMenu: false,
				dicionaryRoutes: {
					transaction: {
						idx: 3,
						incomeRecord: 0,
						expenseRecord: 1,
						receiveRecord: 2
					}
				},
				possuiPermissao
			};
		},

		props: {},

		computed: {
            ...mapState(["usuarioLogado"]),
			activePath () {
				return 	this.$route.path;
			}
		},

		methods: {
			showRoute (path) {
				if ( this.$route.path === path) return;
				const nomeRoute = path.substring(1).split("/");

				if (nomeRoute.length == 2) {
					const idxPath = this.dicionaryRoutes[nomeRoute[0]].idx;
					const idxChildren = this.dicionaryRoutes[nomeRoute[0]][nomeRoute[1]];
					this.$router.options.routes[idxPath].children[idxChildren].props.newRecord = true;
				}
				this.$router.push({ path });
			}
		}
	};
</script>