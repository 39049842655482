const env = process.env.NODE_ENV;
let API = null;
let CREATEUSER = null;

switch (env) {
	case "production":
		API = "/api";
		CREATEUSER = "https://controlecontas.ddns.net";
		break;
	case "staging":
		API = "http://127.0.0.1:3000/api";
		CREATEUSER = "http://127.0.0.1:3002";
		break;
	default:
		API = "http://localhost:3000/api";
		CREATEUSER = "http://localhost:3002";
		break;
}
console.log(env, API);

export default {
	API,
	CREATEUSER
};
